<!--
 * @Description: 
 * @Author: 爱酱丶
 * @Date: 2022-11-08 17:52:24
 * @LastEditTime: 2022-12-11 14:11:17
 * @LastEditors: 爱酱丶
-->
<template>
  <div class="lever-deal">
    <div class="deal">
      <!-- 左侧 -->
      <div class="deal-center">
        <div class="mb-24">
          <!-- 头部信息展示 -->
          <div class="info">
            <div class="info-content">
              <!-- 下拉框 -->
              <div class="down-menu" @click="show2 = true">
                <span class="coin-name">{{ showProductName() }}/USDT</span>
                <div class="svg-icon mt-1 icon-ic_down"></div>
                <!-- <div class="svg-icon mt-1 icon-ic_up"></div> -->
              </div>

              <div class="tradata-right">
                <ul
                  class="header-ul"
                  :style="{
                    color:
                      productNowInfo[activeIndex].BasicPrice -
                        productNowInfo[activeIndex].Price >
                      0
                        ? basicConfig.FallColour
                        : basicConfig.RiseColour
                  }"
                >
                  <li class="family-d">
                    <div class="text">
                      {{ productNowInfo[activeIndex].Price }}
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('pcrapidly').zf }}</div>
                    <div class="number red">-6.48%</div>
                  </li>
                  <li>
                    <div class="title">{{ $t('contract').gao }}</div>
                    <div class="number">
                      {{ productNowInfo[activeIndex].High }}
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('contract').di }}</div>
                    <div class="number">
                      {{ productNowInfo[activeIndex].Low }}
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('contract').liang }}</div>
                    <div class="number">
                      {{ productNowInfo[activeIndex].Amount }}
                    </div>
                  </li>
                  <!-- <li>
                    <div class="title">24H成交额</div>
                    <div class="number">$757.98M</div>
                  </li> -->
                </ul>
              </div>
            </div>
            <!-- 白天夜间切换 -->
            <div class="icon"></div>
          </div>
          <!-- k线图 -->
          <div class="data-show">
            <div class="k-chart">
              <!-- <div class="tool-box">
                <div
                  class="box"
                  v-for="(dItem, dIndex) in dataModeList"
                  :key="dIndex"
                  :class="{ active: dataMoeActiveIndex === dIndex }"
                  @click="changedataMode(dIndex)"
                >
                  {{ dItem }}
                </div>
              </div>
              <div class="echars" v-if="dataMoeActiveIndex === 0">
                <EcharI
                  width="73.4vw"
                  height="41.6vw"
                  :Ldata="Ldata"
                  :split="split"
                  id="a"
                />
              </div>
              <div class="echars" v-else>
                <EcharK
                  :dayMode="dataMoeActiveIndex === 5 ? true : false"
                  width="100%"
                  :Kdata="Kdata"
                  height="93%"
                  id="b"
                  :lastPrice="lastPrice"
                />
              </div> -->
              <TradingView :TvCode="select_tv_code" Height="540px" ref="tra"></TradingView>
              <!--  <EcharK
                width="100%"
                :Kdata="Kdata"
                height="93%"
                id="b"
                :lastPrice="lastPrice"
              /> -->
            <!-- </div> -->
            <!-- 表格数据展示 -->
            <!-- <div class="order-box"> -->
            <!-- 卖单 -->
            <!-- <div class="sell-text">卖单</div> -->
            <!-- <div class="table-head">
                <div class="left">价格(USDT)</div>
                <div class="right">交易量</div>
              </div> -->
            <!-- 表格 -->
            <!-- <div class="table">
                <div class="row" v-for="index in 10" :key="index">
                  <div class="left red">123</div>
                  <div class="right">456</div>
                </div>
              </div> -->
            <!-- 买单 -->
            <!-- <div class="sell-text">买单</div>
              <div class="table-head">
                <div class="left">价格(USDT)</div>
                <div class="right">交易量</div>
              </div> -->
            <!-- 表格 -->
            <!-- <div class="table">
                <div class="row" v-for="index in 10" :key="index">
                  <div class="left green">123</div>
                  <div class="right">456</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- 底部tab -->
        <!-- Tab -->
        <div class="bottom-tab">
          <!-- <el-tabs v-model="tabActiveName">
            <el-tab-pane label="持仓" name="position"> -->
          <List ref="orderList" :productInfo="productNowInfo[activeIndex]" />
          <!-- <el-table :data="tableData" style="width: 100%">
                <el-table-column
                  align="center"
                  prop="date"
                  label="数量"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="name"
                  label="购买价"
                  width="180"
                >
                </el-table-column>
                <el-table-column align="center" prop="address" label="当前价">
                </el-table-column>
              </el-table> -->
          <!-- </el-tab-pane>
            <el-tab-pane label="历史" name="history">配置管理</el-tab-pane>
          </el-tabs> -->
        </div>
      </div>
      <!-- 右侧 -->
      <!-- <Buy :buyModeList="buyModeList" :ProductInfo="ProductInfo" /> -->
      <div class="handicap">
        <div class="tops">
          <div class="text">{{ $t('pcrapidly').dd }}</div>
          <div class="box">
            <p>{{ $t('pcrapidly').bdsj }}:</p>
            {{ this.nowDate }}
            {{ this.nowTime }}
            {{ this.nowAM }}
          </div>
        </div>
        <div class="available">
          <div class="left">
            <span class="s1">{{ $t('pcrapidly').ky }}</span>
            <span class="s2">{{ USDT }} USDT</span>
          </div>
          <img src="../../assets/pc/下载 (3).png" alt="" />
        </div>
        <div class="bottom" @click.stop="">
          <!-- 开仓数量 -->
          <p>{{ $t('contract').kcsl }}</p>
          <div class="input">
            <el-input v-model="Amount" placeholder=""></el-input>
          </div>
          <!-- 开仓时间 -->
          <p>{{ $t('contract').kcsj }}</p>
          <div class="timeList">
            <div
              class="item"
              v-for="(item, index) in buyModeList2"
              :key="index"
              :class="{ active2: timeIndex === index }"
              @click="timeIndex = index"
            >
              {{ item.Time }}
            </div>
          </div>
          <!-- 买涨跌 -->
          <div class="btnbox ">
            <!-- <div class="btn" title="1">买涨</div>
            <div class="btn" title="0">买跌</div> -->
            <div
              class="btn"
              @click="handleBuy('UP')"
              :style="{
                background: basicConfig.RiseColour
              }"
            >
              {{ $t('contract').mz }}
            </div>
            <div
              class="btn"
              @click="handleBuy('Down')"
              :style="{
                background: basicConfig.FallColour
              }"
            >
              {{ $t('contract').md }}
            </div>
          </div>
        </div>
        <div class="buyBox" v-if="buyMode">
          <div
            class="top"
            :style="{
              background:
                mode !== 'UP' ? basicConfig.FallColour : basicConfig.RiseColour
            }"
          >
            <span>
              {{ mode === 'UP' ? $t('contract').mz : $t('contract').md }}
            </span>
            <van-icon name="cross" @click="handleCancle" />
          </div>
          <div class="middle">
            <div>
              <span>{{ $t('contract').sl }}：</span> {{ Amount }}
              USDT
            </div>
            <div>
              <span>{{ $t('contract').sj }}：</span>
              {{ buyModeList2[this.timeIndex].Time }}
            </div>
            <div>
              <span>{{ $t('contract').yll }}：</span>
              {{ buyModeList2[this.timeIndex].Win * 100 }} %
            </div>
          </div>
          <div class="btn-box">
            <div class="btn" @click="handleCancle">
              {{ $t('contract').qc }}
            </div>
            <div
              class="btn "
              :style="{
                background: basicConfig.RiseColour
              }"
              v-if="mode === 'UP'"
              @click="handleCreateOrder('Rise')"
            >
              {{ $t('contract').mz }}
            </div>
            <div
              class="btn "
              :style="{
                background: basicConfig.FallColour
              }"
              v-else
              @click="handleCreateOrder('Fall')"
            >
              {{ $t('contract').md }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="show2"
      position="left"
      :style="{ width: '262px', height: '100vh' }"
    >
      <PopupInfo :productNowInfo="productNowInfo" @change="changeProduct" />
    </van-popup>
  </div>
</template>

<script>
// import buy from './components/buy.vue'
import PopupInfo from './components/popupInfo.vue'
// import EcharK from '@/components_pc/echartK'
// import EcharI from '@/components/echarI'
import productApi from '@/api/product'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
import List from './components/list'
import userApi from '@/api/user'
import formate from '@/mixins'
import TradingView from "@/components/TradingView"
export default {
  name: 'CurrencyMobileNewIndex',
  mixins: [formate],
  components: { TradingView, List, PopupInfo },
  props: {
    ProductInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    buyModeList: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      select_tv_code:'',
      ProductInfo2: this.ProductInfo,
      buyModeList2: this.ProductInfo,
      show2: false,
      show: false,
      Amount: 0,
      // activeIndex: 0,
      buyMode: false,
      mode: true,
      USDT: 0.0,
      nowAM: '',
      nowDate: '',
      nowTime: '',
      nowWeek: '',
      tabActiveName: 'position',
      activeName: 'first',
      activeIndex: 0,
      dataMoeActiveIndex: 2,
      Kdata: [],
      lastPrice: null,
      productList: [],
      productNowInfo: [
        {
          BasicPrice: 0,
          Price: 0,
          High: 0,
          Low: 0,
          Amount: 0
        }
      ],
      dataModeList: [],
      buyForm: {
        name: ''
      },
      Ldata: [],
      split: [],
      timeIndex: 0
    }
  },

  watch: {
    ProductInfo(value) {
      this.Amount = value.BuyLow
    },
    buyModeList() {
      this.activeIndex = 0
    }
  },

  mounted() {
    this.firstEnter = false
    this.change()
    this.getList()
    this.signalRStart()
    this.currentTime()
    this.getUserInfo()
    this.Amount = this.ProductInfo2.BuyLow
  },

  methods: {
    goCoin() {
      sessionStorage.setItem('coin', this.productNowInfo[this.activeIndex].Id)
      this.$router.push({
        name: 'Trade'
      })
    },
    goContract() {
      sessionStorage.setItem(
        'leverage',
        this.productNowInfo[this.activeIndex].Id
      )
      this.$router.push({
        name: 'Leverage'
      })
    },
    getRole() {
      const Role = (
        ((this.productNowInfo[this.activeIndex].Price -
          this.productNowInfo[this.activeIndex].BasicPrice) /
          this.productNowInfo[this.activeIndex].Price) *
        100
      ).toFixed(2)
      return Role > 0 ? `+${Role}` : Role
    },
    handleBuy(mode) {
      this.show = true
      this.buyMode = true
      this.mode = mode
    },
    handleCancle() {
      this.show = false
      this.buyMode = false
    },
    async handleCreateOrder(Direction) {
      let obj = {
        ProductId: this.ProductInfo2.Id,
        Direction,
        TimeId: this.buyModeList2[this.timeIndex].Id,
        Amount: Number(this.Amount)
      }
      if (obj.Amount === 0) {
        this.$toast({
          position: 'bottom',
          message: this.$t('contract').placeholder
        })
        return
      }
      if (
        obj.Amount <= this.buyModeList2[this.timeIndex].LowLimit ||
        obj.Amount >= this.buyModeList2[this.timeIndex].HighLimit
      ) {
        this.$toast({
          position: 'center',
          message: `${this.$t('contract').kcslfw}${
            this.buyModeList2[this.timeIndex].LowLimit
          } - ${this.buyModeList2[this.timeIndex].HighLimit}`
        })
        return
      }
      console.log(this.buyModeList2[this.activeIndex])
      await productApi.createOrder(obj)
      this.handleCancle()
      this.$toast.success(this.$t('contract').SuccessfulPurchase)
      EventBus.$emit('createOrder')
    },

    /* 获取余额 */
    async getUserInfo() {
      const res = await userApi.userInfo()
      this.USDT = res.LegalAmountInfo.USDT
    },
    /* 获取当前时间 */
    currentTime() {
      setInterval(this.formatDate, 500)
    },
    formatDate() {
      //获取当前时间并打印
      let myDate = new Date()
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm = myDate.getMonth() + 1
      let dd = String(
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      )
      let hou = String(
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      )
      let min = String(
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      )
      let sec = String(
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      )
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六'
      ]
      if (hou > 12) {
        hou -= 12
        this.nowAM = ' PM'
      } else {
        this.nowAM = ' AM'
      }
      let week = weeks[wk]
      this.nowDate = yy + ' - ' + mm + ' - ' + dd
      this.nowTime = hou + ' : ' + min + ' : ' + sec
      this.nowWeek = week
    },

    // 展示币种信息
    showProductName() {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === this.ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    },
    change() {
      let product = this.$t('contract')
      this.dataModeList = [
        product.Trend,
        '3' + product.smallMin,
        '5' + product.smallMin,
        '15' + product.smallMin,
        '1' + product.hour,
        '1' + product.dayMin
      ]
    },
    async getList() {
      this.productList = await productApi.list()
      const ProductId = this.$route.params.id
      if (ProductId) {
        this.ProductId = ProductId
        sessionStorage.setItem('product', ProductId)
      } else {
        const ProductId1 = sessionStorage.getItem('product')
        this.ProductId = ProductId1
          ? Number(ProductId1)
          : this.productList[0].Id
      }

      this.productNowInfo = this.productList
      this.firstSetTabProduct()
    },
    // 获得购买选项
    getbuyModeList(index) {
      this.ProductInfo2 = this.productList[index]
      this.buyModeList2 = this.productList[index].Proportion
      /*    this.buyModeList2.forEach((item) => {
        item.Time = this.dateFormate(item.Time)
      }) */

      this.buyModeList2.forEach((item) => {
        item.Time = this.dateFormate(item.Time)
      })
      // console.log(this.buyModeList2)
    },
    firstSetTabProduct() {
      if (this.firstEnter) {
        // 说明不是第一次进入
        return
      }
      this.productList.forEach((item, index) => {
        if (item.Id === this.ProductId) {
          this.firstEnter = true
          this.activeIndex = index
          // 获得购买选项
          this.getbuyModeList(index)
          // this.getKData(2)
          this.select_tv_code = item.TraditionalName
          this.$refs.tra.tradingview( this.select_tv_code)
          return
        }
      })
    },
    // 选择产品
    changeProduct(index) {
      this.show2 = false
      this.activeIndex = index
      this.ProductId = this.productList[index].Id
      sessionStorage.setItem('product', this.ProductId)
      this.dataMoeActiveIndex = 2
      this.getbuyModeList(index)
      // this.getKData(2)
      this.select_tv_code = this.productList[index].TraditionalName
      this.$refs.tra.tradingview( this.select_tv_code)
      this.$refs.orderList.reset()
    },
    // 选择分时图，1M,3M,5M,15M
    changedataMode(index) {
      this.dataMoeActiveIndex = index
      this.getKData(index)
    },
    async getSecondData(data) {
      let productNowInfo = data
      let productList = [...this.productList]
      productNowInfo.forEach((item) => {
        productList.some((pItem) => {
          if (pItem.Id === item.ProductId) {
            pItem.Price = item.Price
            pItem.Rise = item.Rise
            pItem.High = item.High
            pItem.Low = item.Low
            pItem.Amount = item.Amount
            return true
          }
        })
      })

      this.productNowInfo = productList
      if (!this.dataMoeActiveIndex) {
        data.forEach((item) => {
          if (item.ProductId === this.ProductId) {
            this.Ldata.push(item.Price)
            this.split.push('')
            if (this.Ldata.length > 100) {
              this.Ldata.shift()
              this.split.shift()
            }
          }
        })
      }
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)

      connection.on('secondPrices', function(res) {
        that.getSecondData(res)
      })
    },
    // 获取数据
    async getKData(mode) {
      let res = []
      // console.log(mode)
      switch (mode) {
        case 1:
          res = await productApi.get3M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 2:
          res = await productApi.get5M(this.ProductId)

          this.lastPrice = res[res.length - 1]
          // console.log(this.lastPrice)
          break
        case 3:
          res = await productApi.get15M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 4:
          res = await productApi.get1Hour(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 5:
          res = await productApi.getOneDay(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        default:
          this.Ldata = []
          this.getLata()
          this.split = [...Array(100)].map(() => '')
      }
      this.Kdata = res
    },
    async getLata() {
      const result = await productApi.getOneSecondPrices(this.ProductId)
      result.forEach((item) => {
        this.Ldata.push(item.Price)
      })
    }
  },
  computed: {
    lang() {
      this.change()
      return this.$store.getters.lang
    },
    ...mapGetters(['connection', 'basicConfig'])
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate) // 在Vue实例销毁前，清除时间定时器
    }
  }
}
</script>

<style lang="less" scoped>
.lever-deal {
  background-color: #2a2a2a !important;
  padding: 20px 0;
  .deal {
    margin: 0 auto;
    width: 90.77%;
    justify-content: space-between;
    display: flex;
    .deal-center {
      width: 80.98%;
      min-height: 775px;
      border-radius: 8px;
      .mb-24 {
        margin-bottom: 24px;
        .info {
          background-color: #1e1e1e !important;
          height: 60px;
          padding: 0 16px;
          margin-bottom: 16px;
          border-radius: 8px;
          align-items: center;
          justify-content: space-between;
          display: flex;
          .info-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .down-menu {
              margin-right: 10px;
              color: #f6f6f6;
              position: relative;
              cursor: pointer;
              font-weight: 700;
              font-size: 26px;
              font-family: DINPro;
              display: flex;
              align-items: center;
              .coin-name {
                font-family: DINPro;
                margin-right: 10px;
              }
              .icon-ic_down {
                background-image: url('../../assets/pc/svg/download.png');
              }
              .svg-icon {
                width: 1em;
                height: 1em;
                vertical-align: -0.15em;
                fill: currentColor;
                overflow: hidden;
              }
            }
            .header-ul {
              justify-content: space-between;
              display: flex;
              li {
                min-width: 120px;
                text-align: left;
                &:nth-child(n + 1) {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }
                &,
                .family-d {
                  font-family: DINPro;
                }
                .text {
                  line-height: 34px;
                  color: #da5252;
                  margin-bottom: 2px;
                  font-weight: 700;
                  font-size: 18px;
                }
                .title {
                  color: #bdbdbd;
                  margin-bottom: 4px;
                  font-size: 10px;
                }
                .number {
                  color: #f6f6f6;
                  font-weight: 700;
                  font-size: 14px;
                  font-family: DINPro;
                }
                .red {
                  color: #da5252;
                }
              }
            }
            .tradata-right {
              width: 580px;
              padding-right: 16px;
            }
          }
          .icon {
          }
        }
        .data-show {
          display: flex;
          justify-content: space-between;

          .k-chart {
            position: relative;
            width: 100%;
            height: 540px;
            border-radius: 8px;
            .tool-box {
              width: 100%;
              height: 38px;
              // background-color: pink;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #131722;
              .box {
                color: #999999;
                display: flex;
                align-items: center;
                padding: 0 30px;
                height: 100%;
                cursor: pointer;
              }
              .active {
                color: #218dbe;
                border-bottom: 0.01rem solid #218dbe;
              }
            }
            .echars {
              height: 100%;
            }
          }
          // .order-box {
          //   background-color: #1e1e1e !important;
          //   width: 17.85%;
          //   min-height: 530px;
          //   padding: 10px;
          //   box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
          //   border-radius: 4px;
          //   .sell-text {
          //     color: #f6f6f6;
          //     min-height: 23px;
          //     width: 28px;
          //     margin-right: 24px;
          //     position: relative;
          //     white-space: nowrap;
          //     text-align: left;
          //     margin-bottom: 12px;
          //     font-weight: 700;
          //     &::after {
          //       content: '';
          //       width: 100%;
          //       height: 2px;
          //       bottom: 0;
          //       left: 0;
          //       border-radius: 2px;
          //       background: #3d67fa;
          //       position: absolute;
          //     }
          //   }
          //   .table-head {
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
          //     color: #bdbdbd;
          //     margin-bottom: 11px;
          //     font-size: 10px;
          //   }
          //   .table {
          //     margin-bottom: 30px;
          //     font-size: 10px;
          //     .row {
          //       line-height: 18px;
          //       font-weight: 600;

          //       display: flex;
          //       justify-content: space-between;
          //       .right {
          //         color: #bdbdbd;
          //       }
          //     }
          //     .red {
          //       color: #da5252;
          //     }
          //     .green {
          //       color: #60ad76;
          //     }
          //   }
          // }
        }
      }
      .bottom-tab {
        width: 100%;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
        margin: 0 auto;
        border-radius: 8px;
        background-color: #1e1e1e !important;

        .el-tabs {
          // border-bottom: 1px solid #676767;
          & /deep/.el-tabs__nav-wrap::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #676767;
            z-index: 1;
          }
          & /deep/ .el-tabs__nav {
            height: 40px;
            padding-left: 28px;
            .el-tabs__item {
              line-height: 40px;
              color: #bdbdbd;
              font-weight: 600;
              font-size: 14px;
            }
            .is-active {
              color: #fff;
            }
            .el-tabs__active-bar {
              left: 28px;
            }
          }
          & /deep/ .el-tabs__content {
            padding: 28px;
            .el-table {
              tr,
              th {
                background-color: #1e1e1e;
              }
            }
          }
        }
      }
    }
    .handicap {
      background-color: #1e1e1e !important;
      width: 17.58%;
      padding: 10px 10px 0;
      border-radius: 8px;
      .tops {
        height: auto;
        border-bottom: 1px solid #323232;
        padding-bottom: 12px;
        margin-bottom: 12px;
        .text {
          color: #f6f6f6;
          font-weight: 600;
          margin-bottom: 20px;
          text-align: left;
          font-size: 14px;
        }
        .box {
          text-align: center;
          width: 100%;
          background: rgba(46, 150, 246, 0.1);
          border-radius: 76px;
          padding: 6px;
          box-sizing: border-box;
          color: #2e96f6;
          font-weight: 600;
          font-size: 14px;
          font-family: DINPro;
          line-height: 20px;
          padding: 10px 0;
          p {
            color: #bdbdbd;
            margin-bottom: 2px;
            font-size: 10px;
          }
        }
      }
      .available {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        .left {
          font-size: 10px;
          .s1 {
            color: #bdbdbd;
            margin-right: 8px;
            font-size: 10px;
          }
          .s2 {
            color: #f6f6f6;
            font-weight: 700;
            font-family: DINPro;
            font-size: 10px;
          }
        }
        img {
          width: 15px;
          height: 12px;
        }
      }
      .bottom {
        p {
          line-height: 26px;
          font-size: 14px;
        }
        .input {
          .el-input {
            height: 100%;
            background: none;
            & /deep/ .el-input__inner {
              color: #f6f6f6;
              border: 1px solid #676767;
              background: none !important;
              text-align: left;
              padding: 5px;
              box-sizing: border-box;
              margin: 10px 0;
              height: 100%;
            }
          }
        }
        .active2 {
          background: url('~@/assets/new/selects.png') right bottom no-repeat;
          background-size: auto 100%;
        }
        .timeList {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          .item {
            text-align: center;
            width: 47%;
            margin-bottom: 10px;
            padding: 10px 20px;
            border: 1px solid #676767;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .btnbox {
          margin-top: 20px;
          color: #fff;
          cursor: pointer;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-around;
          box-sizing: border-box;
          .btn {
            width: 40%;
            height: 36px;
            line-height: 36px;
            margin-left: 0;
            border-radius: 4px;
            text-align: center;
          }
        }
      }
      .buyBox {
        height: 213px;
        width: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        border-radius: 3px;
        background: #202833;
        .top {
          display: flex;
          padding: 0 10px;
          align-items: center;
          height: 42px;
          font-size: 14px;
          justify-content: space-between;
          color: #fff;
          background: #4f986f;
          border-bottom: 1px solid #eee;
        }
        .middle {
          text-align: center;
          padding: 15px 10px;
          span {
            width: 50%;
            text-align: right;
            display: inline-block;
          }
          div {
            font-size: 14px;
            margin-top: 12px;
            text-align: left;
          }
        }
        .btn-box {
          padding: 10px 15px 0;
          display: flex;
          justify-content: space-between;

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            font-size: 16px;
            background-color: #1e9fff;
            color: #fff;
            width: 146px;
            border-radius: 3px;
          }
          .green {
            background: #4f986f;
          }
        }
      }
    }
  }
}
</style>
